body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Customizes the scrollbar's width and color globally */
*::-webkit-scrollbar {
  width: 10px; /* Adjust the width of the scrollbar */
}

*::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0); /* Adjust the track (background) color */
  border-radius: 6px; /* Optional: adds rounded corners to the scrollbar thumb */

}

*::-webkit-scrollbar-thumb {
  background: rgba(198, 126, 158, 0.359); /* Adjust the thumb color */
  border-radius: 6px; /* Optional: adds rounded corners to the scrollbar thumb */
}



@tailwind base;
@tailwind components;
@tailwind utilities;
